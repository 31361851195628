<template>
  <TTView>
    <VRow>
      <VCol>
        <VCard>
          <VToolbar flat>
            <VBtn
              icon
              :to="{ name : Names.R_CONTENT_STORAGE_COURSES }"
            >
              <VIcon>fal fa-chevron-left</VIcon>
            </VBtn>
            <VToolbarTitle>Создание курса</VToolbarTitle>
          </VToolbar>
          <VDivider />
          <VCardText>
            <VTextField
              v-model="title"
              label="Название курса"
            />
            <VTextarea
              v-model="description"
              label="Описание курса"
            />
            <VAlert
              colored-border
              border="left"
              type="warning"
              elevation="1"
            >
              Не забудьте метки:

              <ul>
                <li>
                  <strong>video_type</strong> = netology_video (временный костыль, чтобы навык с курсом можно было
                  привязывать к нескольким компаниям)
                </li>
                <li><strong>skill</strong> = id навыка</li>
                <li><strong>level</strong> = уровень навыка</li>
              </ul>
            </VAlert>

            <div>
              <h2 class="title mb-3">
                Добавить метку
              </h2>
              <VTextField
                v-model="labelName"
                label="Имя"
              />
              <VTextField
                v-model="labelValue"
                label="Значение"
              />
              <VBtn
                depressed
                color="primary"
                @click="addLabel"
              >
                Добавить метку
              </VBtn>
            </div>

            <div
              v-if="labels.length"
              class="mt-3"
            >
              <span class="subheader">Добавленные метки</span>
              <VChipGroup>
                <VChip
                  v-for="label in labels"
                  :key="`${label.name}_${label.value}`"
                  close
                  text-color="black"
                  @click:close="removeLabel(label)"
                >
                  <span>
                    <span class="font-weight-bold">name:</span> {{ label.name }}&nbsp;
                    <span class="font-weight-bold">value:</span> {{ label.value }}
                  </span>
                </VChip>
              </VChipGroup>
            </div>
          </VCardText>
          <VCardActions class="mt-2 px-4">
            <VBtn
              depressed
              color="primary"
              large
              @click="createCourse"
            >
              Создать курс
            </VBtn>
          </VCardActions>
        </VCard>
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import { queryStringify } from '@front.backoffice/common';

export default {
  name: 'CourseCreate',
  inject: ['Names'],
  data() {
    return {
      title: '',
      description: '',
      labelName: '',
      labelValue: '',
      labels: [],
    };
  },
  methods: {
    clearLabel() {
      this.labelName = '';
      this.labelValue = '';
    },
    addLabel() {
      const label = {
        name: this.labelName,
        value: this.labelValue,
      };

      this.labels.push(label);
      this.clearLabel();
    },
    removeLabel(label) {
      this.labels = this.labels.filter(({ name, value }) => name !== label.name && value !== label.value);
    },
    async createCourse() {
      const course = await this.getCourse(this.labels);

      if (course) {
        this.goToCourseEdit();
        return;
      }

      const payload = {
        title: this.title,
        description: this.description,
        labels: this.labels,
        topics: [],
      };

      try {
        await this.$di.api.ContentStorage.courseItemCreate(payload);
        this.$di.notify.snackSuccess('Курс успешно создан');
        this.goToCourseEdit();
      } catch (error) {
        this.$di.notify.errorHandler(error);
      }
    },

    async getCourse(labels) {
      let course = null;
      try {
        const response = await this.$di.api.ContentStorage.courseItemGet({ labels });
        course = response.course;
      } catch (error) {
        if (error.response.status !== 404) this.$di.notify.errorHandler(error);
      }

      return course;
    },

    goToCourseEdit() {
      this.$router.push({
        name: this.Names.R_CONTENT_STORAGE_COURSE_EDIT,
        query: {
          // Note: Целью парсинга всегда должен быть объект, поэтому приходится массив вкладывать в объект
          labels: queryStringify({ labels: this.labels }),
        },
      });
    },
  },
};
</script>
