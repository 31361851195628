const { VUE_APP_GOGHA_API_ADDRESS: baseUrl = 'http://localhost:3000' } = process.env;

function getImageUrl(opts) {
  const {
    uuid, type, name, size,
  } = opts;

  let url = `${baseUrl}${type}/${uuid}/${name}`;

  if (size) url += `/${size}`;

  return url;
}

export default getImageUrl;
