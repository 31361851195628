// [
//   {
//     id: 'position.id',
//     type: 'position',
//     name: 'position.name',
//     item: {},
//     children: [
//      {
//        id: 'competency.id',
//        type: 'competency',
//        name: 'competency.name',
//        item: {},
//        children: [
//          {
//            id: 'skill.id',
//            type: 'skill',
//            name: 'skill.name',
//            item: {},
//            children: [],
//          },
//        ],
//      },
//     ],
//   },
// ]
function normalizeCompanyMatrix(raw) {
  if (!Array.isArray(raw)) {
    return [];
  }

  const isPositionNode = (node) => node.position !== undefined;
  const isCompetencyNode = (node) => node.position === undefined && node.level === undefined;
  const isSkillNode = (node) => node.level !== undefined;
  const getNodeType = (node) => {
    let nodeType = null;

    if (isPositionNode(node)) {
      nodeType = 'position';
    } else if (isCompetencyNode(node)) {
      nodeType = 'competency';
    } else if (isSkillNode(node)) {
      nodeType = 'skill';
    }

    return nodeType;
  };

  const getNodeItem = (node) => {
    let nodeItem = {};

    if (isPositionNode(node)) {
      nodeItem = { ...node.position, childrenKey: 'matrix' };
    } else if (isCompetencyNode(node)) {
      const { id, name, description } = node;

      nodeItem = {
        id,
        name,
        description,
        childrenKey: 'skills',
      };
    } else if (isSkillNode(node)) {
      const {
        id, level, name, instrument, parameters,
      } = node;

      nodeItem = {
        id,
        level,
        name,
        instrument,
        parameters,
        childrenKey: null,
      };
    }

    return nodeItem;
  };

  const getNode = (i) => {
    const type = getNodeType(i);
    const item = getNodeItem(i);
    const { id, name } = item;

    const node = {
      id,
      type,
      name,
      item,
      children: [],
    };

    return node;
  };

  function normalizeNode(i) {
    const node = getNode(i);

    if (i[node.item.childrenKey]?.length > 0) {
      node.children = i[node.item.childrenKey].map(normalizeNode);
    }

    return node;
  }

  const tree = raw.map(normalizeNode);

  return tree;
}

export default normalizeCompanyMatrix;
