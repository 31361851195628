export const fio = (lastName, firstName, middleName) => [lastName, firstName, middleName].filter((n) => n).join(' ');

export const avatarText = (firstName, lastName) => {
  if (firstName && lastName) {
    return `${firstName[0]}${lastName[0]}`;
  }

  return null;
};

export default {
  fio,
  avatarText,
};
