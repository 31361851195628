export default class TransportPolling {
  constructor(response, {
    count = 0,
    delay = 2000,
    retryLimit = 50,
  } = {}) {
    this.response = response;
    this.count = count;
    this.delay = delay;
    this.retryLimit = retryLimit;
    this.timerId = null;
    this.stopPolling = false;
  }

  start() {
    this.stopPolling = false;
    this.execute();
  }

  stop() {
    clearTimeout(this.timerId);
    this.count = 0;
    this.stopPolling = true;
  }

  execute = () => {
    this.response().finally(this.waitAndExecute);
  }

  waitAndExecute = () => {
    this.count += 1;
    if (this.stopPolling || (this.retryLimit && this.count === this.retryLimit)) {
      return;
    }
    this.timerId = setTimeout(this.execute, this.delay);
  }
}
